@import url(https://fonts.googleapis.com/css?family=Lato:400,700&display=swap);
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.offset:before {
  display: block;
  content: "";
  height: 3.6rem;
  margin-top: -3.6rem;
}

.navbar {
  font-weight: 900;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
}

.bg-dark-opaque {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.navbar-brand-link,
.navbar-brand-link:hover {
  text-decoration: none;
  color: white;
}

.nav-link {
  color: white !important;
  padding-top: 0.4rem;
  transition: all 0.3s ease 0s;
}

.nav-link:hover {
  color: #00ac92 !important;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.content-section {
  width: 90%;
  margin: 2rem auto;
}

